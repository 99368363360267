<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Tranferencias</span>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn v-if="this.$hasPermision('transferencias.create')" dark color="#023145" width="140" class="float-right"
                 @click="handleNewTransfer">
            Nueva
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-1">
          <v-btn dark color="#023145" outlined width="140" class="float-right" @click="handleShowFilters">
            <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
            Ver Filtros
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-0">
          <v-card>
            <v-data-table
                :headers="headers"
                :items="transfers"
                class="table-transfers elevation-1 w-table"
                height="59vh"
                :loading="loading"
                :options.sync="options"
                :server-items-length="paginated.total"
                loading-text="Loading... Please wait">
              <template v-slot:top>
                                <span class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todas las tranferencias
                                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.register_date="{item}">
                <span>{{ item.register_date | moment("DD/MM/YYYY") }}</span>
              </template>

              <template v-slot:item.hour="{item}">
                <span>{{ item.register_date | moment("hh:mm a") }}</span>
              </template>

              <template v-slot:item.origin_id="{item}">
                <span v-if="item.departure">{{ item.departure.name }}</span>
              </template>

              <template v-slot:item.destination_id="{item}">
                <span v-if="item.arrival">{{ item.arrival.name }}</span>
              </template>

              <template v-slot:item.register_by="{item}">
                <span>{{ item.sender.employee.fullname }}</span>
              </template>

              <template v-slot:item.status="{item}">
                                <span :class="'label-status transfer-' + item.status">
                                    <v-icon v-if="item.status == 'recibida'" size="11"
                                            color="#4CAF50">fas fa-check</v-icon>
                                    <v-icon v-if="item.status == 'enviada'" size="11"
                                            color="#3F51B5">fas fa-paper-plane</v-icon>
                                    <v-icon v-if="item.status == 'parcial'" size="11"
                                            color="#FFC107">fas fa-clone</v-icon>
                                    <v-icon v-if="item.status == 'anulada'" size="11" color="#F44336">fas fa-times-circle</v-icon>
                                    {{ item.status }}
                                </span>
              </template>

              <template v-slot:item.received_date="{item}">
                <span v-if="item.received_date">{{ item.received_date | moment("DD/MM/YYYY") }}</span>
              </template>

              <template v-slot:item.hour_received="{item}">
                <span v-if="item.received_date">{{ item.received_date | moment("hh:mm a") }}</span>
              </template>

              <template v-slot:item.received_by="{item}">
                <span v-if="item.receiver">{{ item.receiver.employee.fullname }}</span>
              </template>

              <template v-slot:item.actions="{item}">
                <!--
                <v-btn v-if="item.status == 'enviada' && verifyPermission('transferencias.recepcionar')"
                    x-small
                    dark
                    color="green"
                    @click="handleReceive(item)">
                    Recepcionar
                </v-btn>
                -->
                <v-icon v-if="item.status == 'enviada' && verifyPermission('transferencias.recepcionar')"
                        color="green"
                        size="18"
                        @click="handleReceive(item)"
                        class="mr-2">
                  fas fa-truck-loading
                </v-icon>
                <!--
                <v-btn v-if="item.status == 'enviada' && verifyPermission('transferencias.anular')"
                    x-small
                    dark
                    color="red"
                    class="ml-2"
                    @click="handleCancelTransfer(item)">
                    Anular
                </v-btn>
                -->
                <v-icon
                    v-if="(item.status == 'enviada' || item.status == 'recibida' || item.status == 'parcial') && verifyPermission('transferencias.anular')"
                    color="red"
                    size="18"
                    @click="handleCancelTransfer(item)">
                  far fa-times-circle
                </v-icon>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

    </template>

    <Filters ref="filters"></Filters>
  </v-container>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import router from '@/router/index';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import Filters from '../../../components/transfers/Filters';

export default {
  name: 'Transfers',
  components: {
    Filters,
    OfficeLogin
  },
  data() {
    return {
      itemsBreadcrumbs: [
        {
          text: 'Logística',
          disabled: true,
          href: '',
        }
      ],
      headers: [
        {text: 'FECHA REGISTRO', value: 'register_date', sortable: false, width: 122},
        {text: 'H. REGISTRO', value: 'hour', sortable: false, width: 100},
        {text: 'REGISTRADO POR', value: 'register_by', sortable: false},
        {text: 'ORIGEN', value: 'origin_id', sortable: false},
        {text: 'DESTINO', value: 'destination_id', sortable: false},
        {text: 'ESTADO', value: 'status', sortable: false, width: 135},
        {text: 'FECHA RECEPCIÓN', value: 'received_date', sortable: false, width: 135},
        {text: 'H. RECEPCIÓN', value: 'hour_received', sortable: false, width: 116},
        {text: 'RECIBIDO POR', value: 'received_by', sortable: false},
        {text: '', value: 'actions', sortable: false},
      ],
      loading: false,
      dataTable: {page: 1},
      options: {},
      pageSelected: 1,
      perPage: 10,
    }
  },
  watch: {
    options: {
      handler() {
        console.log(this.options)
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        const search = this.search_product
        console.log(search)
        if (search!=null && search.trim().length > 0) {
          // this.perPage=-1
          this.getTransfers({
            paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,
            name: search
          })
        } else {
          this.getTransfers({paginated: true, page: this.pageSelected, itemsPerPage: this.perPage})
        }
      },
      deep: true,
    }
  },
  computed: {
    ...mapState('authentication', [
      'office_id'
    ]),
    ...mapState('transfers', [
      'transfers',
        'paginated'
    ])
  },
  methods: {
    ...mapActions('transfers', [
      'getTransfers',
      'getDetail',
      'cancel',

    ]),
    ...mapGetters('authentication', [
      'isSuperadmin'
    ]),
    ...mapActions('storehouses', [
      'getStorehouses'
    ]),
    handleNewTransfer() {
      router.push('/app/logistica/nueva-transferencia')
    },
    async handleReceive(item) {
      await this.getDetail(item)
      router.push('/app/logistica/recepcion-transferencia')
    },
    async handleCancelTransfer(item) {
      this.$swal({
        title: "¿Desea confirmar?",
        text: "Confirma que desea anular la transferencia",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          if (result.value === true) {
            await this.cancel(item)
          }
        }
      })
    },
    verifyPermission(permission) {
      return this.$hasPermision(permission)
    },
    verifyDestination(destination) {
      return this.office_id == destination;
    },
    handleShowFilters() {
      this.$refs.filters.showForm('Filtrar Transferencias');
    }

  },
  created() {
    // this.getTransfers({paginated:true});
    this.getStorehouses();
  }
}
</script>

<style scoped>
.table-transfers td, .table-transfers th {
  padding: 0 2px !important;
}
</style>