<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>
        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>

                <v-row v-if="isSuperadmin()">
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.origin_id"
                            :items="storehouses"
                            hide-details
                            label="Almacén origen"
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row v-if="isSuperadmin()">
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.destination_id"
                            :items="storehouses"
                            hide-details
                            label="Almacén destino"
                            outlined
                            :item-text="customText"
                            item-value="id"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.status"
                            :items="item_status"
                            hide-details
                            outlined
                            label="Estado"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleClearFilters">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Quitar filtros
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSearch">
                            <v-icon size="16" class="mr-1">fas fa-search</v-icon>
                            Filtrar datos
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import ArrayTools from '../../helpers/ArrayTools'

export default {
    name: 'Filters',
    data() {
        return {
            drawer: false,
            titleForm: null,
            filters: {
                origin_id: null,
                destination_id: null,
                status: null
            },
            companies: [],
            value_company: null,
            disabled_company: false,
            sedes: [],
            value_sede: null,
            disabled_sede: false,
            offices: [],
            value_office: null,
            value_stock: null,
            item_status: [
                'Enviada', 'Recibida', 'Parcial', 'Anulada'
            ]

        }
    },
    computed: {
        ...mapState('authentication', [
            'select_office',
            'user_companies',
            'user_sedes',
            'user_offices',
            'company_id'
        ]),
        ...mapState('inventories', [
            'main_storehouse',
            'office_id'
        ]),
        ...mapState('storehouses', [
            'storehouses'
        ])
    },
    methods: {
        ...mapGetters('authentication', [
            'isSuperadmin'
        ]),
        ...mapActions('transfers', [
            'getTransfers',
            'getDetail',
            'cancel'
        ]),
        showForm(title) {
            this.titleForm = title
            this.drawer = !this.drawer
        },
        closeForm() {
            this.drawer = false;
        },
        clearData() {
            this.filters.origin_id = null;
            this.filters.destination_id = null;
            this.filters.status = null;
        },
        customText(item){
            let text = item.name
            
            return text
        },
        async handleClearFilters() {
            this.clearData();
            await this.getTransfers()
        },
        async handleSearch() {
            await this.getTransfers(this.filters);
            this.closeForm();
        },
    }
}
</script>

<style scoped>

</style>